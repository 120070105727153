import React from 'react';

// import './GalleryPage.scss';

const GalleryPage = () => {
  return (
    <div className="GalleryPage">
      Gallery Page
    </div>
  );
}

export default GalleryPage;