import React from 'react';

import './AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="AboutUs">
      
      <div className="container about_me" id="about_me">
        <div className="row">
          <div className="col-md-6 text-right">
            <h1>WELCOME TO</h1>
            <h1>CUT MASTER STUDIO</h1>
            <h1>TATTOO & PIERCING</h1>

            <div className="h_line_left"></div>
            <div className="text-right about_me_time"> <h3>NOW OPEN 7 DAYS A WEEK!</h3></div>
            <div className="h_line_right"></div>
          </div>

          <div className="col-md-6 text-left">
            <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. </p>
            <p> I’m a great place for you to write more. Tell a story and let your users get to know you.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;