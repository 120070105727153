import React from 'react';
import CoverImageContainer from './components/CoverImageContainer/CoverImageContainer';
import SocialBox from './components/SocialBox/SocialBox';
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Services';
import Gallery from './components/Gallery/Gallery';
import Testimonials from './components/Testimonials/Testimonials';
import Contact from './components/Contact/Contact';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

import './HomePage.scss';

const HomePage = () => {
  return (
    <div className="HomePage">
      <SocialBox />
      <CoverImageContainer />
      <AboutUs />
      <Services />
      <Gallery />
      <Testimonials />
      <Contact />
      <Navbar />
      <Footer />
    </div>
  );
}

export default HomePage;