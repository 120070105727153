import React from 'react';

import LOGOLIGHT from '../../../../images/logo_light.png';
import BACKGROUND from '../../../../images/para_4.jpg';

import './Services.scss';

const Services = () => {
  return (
    <div className="Services">
      <div className="paralax artist" id="artist">
        <div className="content-space-two" style={{ backgroundImage: `url(${BACKGROUND})`}}>

          <div className="imageHolder">
            <img src={LOGOLIGHT} alt="Mountain View" />
          </div>
          <div className="container">

            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-left"> 
                <h3>JOB 1</h3>
                <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                    start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
            </div>

            <div className="row">

              <div className="col-md-6 text-right"> 
                <h3>JOB 1</h3>
                <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                    start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-left"> 
                <h3>JOB 1</h3>
                <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                    start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
            </div>

            <div className="row">

              <div className="col-md-6 text-right"> 
                  <h3>JOB 1</h3>
                  <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                      start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div> 
        </div>  
      </div>
    </div>
  );
}

export default Services;