import React from 'react';

import './Contact.scss';

const Contact = () => {
  return (
    <div className="Contact">
      <div id="contact">
        <div className="text-center gallery_title">
            <h1>Contact</h1>
        </div>
        <div className="container">
          <form action="#" method="post" data-page="contact_page" data-msg="Contact" id="ajax-contact-form" className="sky-form contact-style">
            <div className="row margin-bottom-30">
                <div>
                    <input id="mail-type" type="hidden" name="mail-type" value="" />
                </div>
                <div className="col-md-6 col-md-offset-0">
                  <div>
                      <input type="text" name="first_name" id="first_name" className="form-control" placeholder="First Name" />
                      <div className="error-holder"></div>
                  </div>
                </div>
                <div className="col-md-6 col-md-offset-0">
                  <div>
                    <input type="text" name="last_name" id="last_name" className="form-control" placeholder="Last Name" />
                    <div className="error-holder"></div>
                  </div>
                </div>
            </div>

            <div className="row margin-bottom-30">
              <div className="col-md-6 col-md-offset-0">
                <div>
                  <input type="text" name="email" id="email" className="form-control" placeholder="Email" />
                  <div className="error-holder"></div>
                </div>
              </div>
              <div className="col-md-6 col-md-offset-0">
                <div>
                  <input type="text" name="phone" id="phone" className="form-control" placeholder="Phone" />
                  <div className="error-holder"></div>
                </div>
              </div>
            </div>

            <div className="row margin-bottom-30">
              <div className="col-md-12 col-md-offset-0">
                <div>
                  <textarea rows="4" name="message" id="message" className="form-control g-textarea-noresize" placeholder="Message"></textarea>
                  <div className="error-holder"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center margin-top-20 margin_bottom_100">
              <button type="submit" className="button_send">Send</button>
            </div>

            <div className="message">
              <i className="rounded-x fa fa-check"></i>
              <p>Your message was successfully sent!</p>
            </div>
          </form>
          <div className="form-message"></div>
        </div>
      </div>
    </div>
  );
}

export default Contact;