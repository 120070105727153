import React from 'react';

import SmoothScroll from 'smooth-scroll';

import './Navbar.scss';

const Navbar = () => {
  var scroll = new SmoothScroll('a[href*="#"]');
  return (
    <div className="Navbar">
      <ul className="">
        <li className="page-scroll ">
            <a href="#home" data-scroll className="scroll">HOME</a>
        </li>
        <li className="page-scroll ">
            <a href="#about_me" data-scroll className="scroll">ABOUT ME</a>
        </li>
        <li className="page-scroll ">
            <a href="#artist" data-scroll className="scroll">ARTIST</a>
        </li>
        <li className="page-scroll ">
            <a href="#gallery" data-scroll className="scroll">GALLERY</a>
        </li>

        <li className="page-scroll ">
            <a href="#contact" data-scroll className="scroll">CONTACT</a>
        </li>
        <li className="page-scroll ">
            <a href="/" data-scroll className="scroll">BOOK ONLINE</a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;