import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className="Footer">
      <p>© 2016 Holycult</p>
    </div>
  );
}

export default Footer;