import React from 'react';

import BACKGROUND from '../../../../images/tattoo-2.jpg';

import './Testimonials.scss';

const Testimonials = () => {
  return (
    <div className="Testimonials">
      <div className="paralax artist" id="artist">
        <div className="content-space-two" style={{ backgroundImage: `url(${BACKGROUND})`}}>

        <div className="text-center">
                        <h1>CUT MASTER</h1>
                    </div>
          <div className="container">

            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-left"> 
                <h3>JOB 1</h3>
                <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                    start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
            </div>

            <div className="row">

              <div className="col-md-6 text-right"> 
                <h3>JOB 1</h3>
                <p>I’m a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me and you can 
                    start adding your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page.</p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div> 
        </div>  
      </div>
    </div>
  );
}

export default Testimonials;