import React from 'react';
import { Router } from '@reach/router';
import { ROUTES } from './routes';
import 'antd/dist/antd.css';

import HomePage from './pages/HomePage/HomePage';
import GalleryPage from './pages/GalleryPage/GalleryPage';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <HomePage path={ROUTES.ROOT} />
        <GalleryPage path={ROUTES.GALLERY} />
      </Router>
    </div>
  );
}

export default App;