import React from 'react';

import IMG1 from '../../../../images/img-01.png';
import IMG2 from '../../../../images/img-02.png';
import IMG3 from '../../../../images/img-03.png';
import IMG4 from '../../../../images/img-04.png';
import IMG5 from '../../../../images/img-05.png';
import BACKGROUND from '../../../../images/tattoo-1.jpg';

import './CoverImageContainer.scss';

const CoverImageContainer = () => {
  return (
    <div className="CoverImageContainer">
      
      <div className="paralax" id="home">
        <div className="content-space-one" style={{ backgroundImage: `url(${BACKGROUND})`}}>
          <div className="row">
            <div className="text-right images_paralax col-md-6 col-sm-6 col-xs-6" id="img-changer">
              <img className='ini' src={IMG1} alt='katamaestro' />
              <img className='other' src={IMG2} alt='katamaestro' />
              <img className='other' src={IMG3} alt='katamaestro' />
              <img className='other' src={IMG4} alt='katamaestro' />
              <img className='other' src={IMG5} alt='katamaestro' />
            </div>
            <div className="h_line"></div>

            <div className="text-left cut_master_studio col-md-6 col-sm-6 col-xs-6">
              <a href="announcment.php"><h1 className="text2">CUT</h1></a>
              <a href="announcment.php"><h1 className="text2">MASTER</h1></a>
              <a href="announcment.php"><h1 className="text2">STUDIO</h1></a>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
}

export default CoverImageContainer;