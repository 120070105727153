import React from 'react';
import { Image } from 'antd';

import GALLERYLOGO from '../../../../images/gallery.png';

import './Gallery.scss';

const Gallery = () => {

  const photosData = [
    {
      id: 0,
      title: "Hair Cut",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 1,
      title: "Hair Color",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 2,
      title: "Beard Trim",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 3,
      title: "Hair Color",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 4,
      title: "Beard Trim",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 5,
      title: "Hair Color",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 6,
      title: "Beard Trim",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    },
    {
      id: 7,
      title: "Beard Trim",
      photo: "https://tympanus.net/Development/HoverEffectIdeas/img/4.jpg"
    }
  ];

  const renderImage = data => {
    const {id, photo} = data;
    return (
      <Image
        width={250}
        src={photo}
        key={id}
      />
    )
  }

  const renderImages = () => {
    return (
      <div className="PhotosContainer">
        <Image.PreviewGroup>
          {photosData?.map(renderImage)}
        </Image.PreviewGroup>
      </div>
    )
  }

  return (
    <div className="Gallery">
      
      <div className="container" id="gallery">
        <div className="text-center gallery_logo">
            <img src={GALLERYLOGO} alt="Mountain View" />
        </div>
        <div className="text-center gallery_title">
            <h1>GALLERY</h1>
        </div>

        <div className="row images">
          {renderImages()}
        </div>
      </div>
    </div>
  );
}

export default Gallery;